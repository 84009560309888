import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'

function TopBar() {
    const [state, setstate] = useState(true);
    const handler = () => {
        setstate(false)
    }
    return (
        <>
            {state &&
                <div className='bg-secondary-200 flex items-center'>
                    <div className='flex-1'><div className='w-10' /> </div>
                    <p className="text-center 2xl:text-sm text-xs text-white w-full">24/7 Service: 1 (800) 944-1424</p>
                    <div className='text-white bg-gray-15 cursor-pointer h-12 w-12 flex items-center justify-center' onClick={handler}>
                        <IoClose className='text-white text-lg' />
                    </div>
                </div>
            }
        </>
    )
}

export default TopBar
