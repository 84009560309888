import React from 'react'

function NewsLetter() {
    return (
        <div className="sm:flex items-center justify-between max-w-5xl mx-auto w-full lg:p-7 py-6">
            <div className="">
                <p className="max-w-sm text-[15px] text-secondary-500">
                Get news and insights from the parking world with our newsletters
                </p>
            </div>
            <div className="mt-6 sm:w-full sm:max-w-sm lg:mt-0 lg:flex-1 bg-gray-300 rounded-md">
                <form className="flex">
                    <input
                        id="email-address"
                        name="email-address"
                        type="email"
                        autoComplete="email"
                        required
                        className="w-full bg-gray-300 rounded-md px-5 py-2 text-xs placeholder-gray-800 text-secondary-200 focus:outline-none"
                        placeholder="Enter your email"
                    />
                    <button
                        type="submit"
                        className="flex items-center justify-center rounded-md border border-transparent bg-primary-100 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-200 focus:outline-none"
                    >
                        Subscribe
                    </button>
                </form>
            </div>
        </div>
    )
}

export default NewsLetter
