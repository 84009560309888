import React from 'react'
import { navigation, address, links } from "@data"
import NewsLetter from '../newsletter'

function Footer() {
    return (
        <footer>
            <NewsLetter />
            <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-5 md:py-8 py-6">
                {links.map((i, j) => {
                    return (
                        <div key={j}>
                            <p className="text-xs text-secondary-300 uppercase font-bold">{i.title}</p>
                            <ul className="sm:py-3 py-2 text-gray-600">
                                {i.list.map((v, k) => {
                                    return (
                                        <li key={k}>
                                            <a href="/" className='sm:text-sm text-xs hover:text-secondary-300 hover:pl-0.5 transition-all'>{v.text}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
                <div className='space-y-4'>{address.map((i, j) => {
                    return (
                        <div key={j}>
                            <p className="text-xs text-secondary-300 font-bold">{i.title}</p>
                            <a href="/" className='sm:text-sm text-xs hover:text-secondary-300 hover:pl-0.5 transition-all text-gray-600'>{i.text}</a>
                        </div>
                    )
                })}</div>
                <div className="">
                    <div>
                        <p className="text-xs text-secondary-300 font-bold">Helpline</p>
                        <a href="/" className='sm:text-sm text-xs hover:text-secondary-300 hover:pl-0.5 transition-all text-gray-600'>011-408-45100</a>
                    </div>
                    <div className="flex items-center space-x-2.5 pt-4">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-secondary-300 transition-all hover:pl-0.5">
                                <span className="sr-only">{item.name}</span>
                                <item.icon className="w-4 h-4 text-secondary-300" aria-hidden="true" />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            <div className="text-center border-t border-gray-100 py-5">
                <p className="text-center 2xl:text-xs text-tinny text-secondary-300">© 2022 Agile Parking Solutions Private Limited</p>
            </div>
        </footer>
    )
}

export default Footer