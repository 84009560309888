import React from 'react';

function Hero() {
    return (
        <div className="services-bg bg-no-repeat">
            <div className="grid grid-cols-2 gap-2 max-w-2xl mx-auto lg:py-26 py-16 p-3">
                {[
                    { name: "17", title: "Countries" }, { name: "60 Million", title: "Parking Transactions" },
                    { name: "2500+", title: "Parking Lots Digitised" }, { name: "3", title: "Smart City Projects" },
                ].map((v, k) => {
                    return <div className="bg-white text-center shadow-xs items-center md:p-8 px-3 py-12" key={k}>
                        <h2 className='text-primary-100 md:text-4xl text-2xl font-semibold'>{v.name}</h2>
                        <p className="text-secondary-300 md:text-xl text-sm pt-2">{v.title}</p>
                    </div>
                })}
            </div>
        </div>
    )
}

export default Hero