import React from 'react'
import { Disclosure } from '@headlessui/react'
import { routes } from '@data'
import { CgMenuRightAlt } from 'react-icons/cg'
import { GrFormClose } from 'react-icons/gr';
import { Button } from 'components/atoms';
import Logo from "assets/logo.png";

export default function Header() {
    return (
        <Disclosure as="nav" className="bg-white border relative z-40">
            {({ open }) => (
                <>
                    <div className="md:px-6 px-4 xl:px-16 sm:py-4 py-2 w-full font-inco">
                        <div className="flex items-center justify-between">
                            <div className="flex-shrink-0">
                                <img
                                    className="h-24 w-auto"
                                    src={Logo}
                                    alt="Your Company"
                                />
                            </div>
                            <div className="hidden lg:block">
                                <div className="flex space-x-8">
                                    {routes.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className='inline-flex items-center text-sm xl:text-base tracking-wider font-semibold transition-all text-gray-650'
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                    <Button value="Contact Us" className="text-uppercase bg-white text-gray-650 py-2.5 px-5 border-2 border-gray-650 hover:bg-gray-650 hover:text-white font-semibold" />
                                </div>
                            </div>
                            <div className="-mr-2 flex lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-sm p-2 text-gray-800 focus:outline-none bg-gray-70">
                                    {open ? (
                                        <GrFormClose className="block h-6 w-6 text-gray-800" aria-hidden="true" />
                                    ) : (
                                        <CgMenuRightAlt className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel as="nav" className="lg:hidden">
                        <div className="flex flex-col space-y-5 py-5 pb-7 md:px-6 px-4">
                            {routes.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    href={item.href}
                                    className='inline-flex items-center text-sm xl:text-base tracking-wider font-semibold transition-all text-gray-650'
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                            <Button value="Contact Us" className="text-uppercase bg-white text-gray-650 py-2.5 px-5 border-2 border-gray-650 hover:bg-gray-650 hover:text-white font-semibold" />
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}