export const routes = [
    {
        name: "SOLUTIONS",
        href: ""
    },
    {
        name: "INDUSTRIES",
        href: ""
    },
    {
        name: "EMPLOYMENT",
        href: ""
    },
    {
        name: "MONTHLY PARKING",
        href: ""
    },
    {
        name: "CLIENT PORTAL",
        href: ""
    },
    {
        name: "FREE QUOTE",
        href: ""
    },
]

export const links = [
    {
        title: "COMPANY",
        list: [
            {
                text: "About Us"
            },
            {
                text: "The team"
            },
            {
                text: "Career"
            },
            {
                text: "Contact Us"
            },
        ]
    },
    {
        title: "PRODUCTS",
        list: [
            {
                text: "Consumer Module"
            },
            {
                text: "Operator Module"
            },
            {
                text: "Enable Module"
            },
            {
                text: "Fleet Parking"
            },
            {
                text: "Report & Admin"
            },
        ]
    },
    {
        title: "SOLUTIONS",
        list: [
            {
                text: "For Operators"
            },
            {
                text: "For Governments"
            },
            {
                text: "For Corporates"
            },
            {
                text: "For Auto OEMs"
            },
        ]
    },
    {
        title: "RESOURCES",
        list: [
            {
                text: "Case Studies"
            },
            {
                text: "Terms & Conditions"
            },
            {
                text: "Privacy Policy"
            },
            {
                text: "Our Blog"
            },
            {
                text: "FAQs"
            },
        ]
    },
]

export const address = [
    {
        title: "For sales queries",
        text: "sales@getmyparking.com"
    },
    {
        title: "For general queries",
        text: "care@getmyparking.com"
    },
    {
        title: "For career",
        text: "talent@getmyparking.com"
    },
]

export const navigation = [
    {
        name: 'Facebook',
        href: '#',
        icon: (props) => (
            <svg viewBox="0 0 12 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M3.69604 11.2481C3.62404 11.2481 2.04004 11.2481 1.32004 11.2481C0.93604 11.2481 0.81604 11.1041 0.81604 10.7441C0.81604 9.78407 0.81604 8.80007 0.81604 7.84007C0.81604 7.45607 0.96004 7.33607 1.32004 7.33607H3.69604C3.69604 7.26407 3.69604 5.87207 3.69604 5.22407C3.69604 4.26407 3.86404 3.35207 4.34404 2.51207C4.84804 1.64807 5.56804 1.07207 6.48004 0.736072C7.08004 0.520072 7.68004 0.424072 8.32804 0.424072H10.68C11.016 0.424072 11.16 0.568072 11.16 0.904072V3.64007C11.16 3.97607 11.016 4.12007 10.68 4.12007C10.032 4.12007 9.38404 4.12007 8.73604 4.14407C8.08804 4.14407 7.75204 4.45607 7.75204 5.12807C7.72804 5.84807 7.75204 6.54407 7.75204 7.28807H10.536C10.92 7.28807 11.064 7.43207 11.064 7.81607V10.7201C11.064 11.1041 10.944 11.2241 10.536 11.2241C9.67204 11.2241 7.82404 11.2241 7.75204 11.2241V19.0481C7.75204 19.4561 7.63204 19.6001 7.20004 19.6001C6.19204 19.6001 5.20804 19.6001 4.20004 19.6001C3.84004 19.6001 3.69604 19.4561 3.69604 19.0961C3.69604 16.5761 3.69604 11.3201 3.69604 11.2481V11.2481Z" fill="currentColor" />
            </svg>
        ),
    },
    {
        name: 'LinkedIn',
        href: '#',
        icon: (props) => (
            <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M19.9334 19.5999V12.5679C19.9334 9.11192 19.1894 6.47192 15.1574 6.47192C13.2134 6.47192 11.9174 7.52792 11.3894 8.53592H11.3414V6.78392H7.52539V19.5999H11.5094V13.2399C11.5094 11.5599 11.8214 9.95192 13.8854 9.95192C15.9254 9.95192 15.9494 11.8479 15.9494 13.3359V19.5759H19.9334V19.5999Z" fill="currentColor" />
                <path d="M1.04541 6.78394H5.02941V19.5999H1.04541V6.78394Z" fill="currentColor" />
                <path d="M3.0374 0.399902C1.7654 0.399902 0.733398 1.4319 0.733398 2.7039C0.733398 3.9759 1.7654 5.0319 3.0374 5.0319C4.3094 5.0319 5.3414 3.9759 5.3414 2.7039C5.3414 1.4319 4.3094 0.399902 3.0374 0.399902Z" fill="currentColor" />
            </svg>
        ),
    },
    {
        name: 'Instagram',
        href: '#',
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },
    {
        name: 'Twitter',
        href: '#',
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
        ),
    },
]