import { TopBar } from "components/molecules";
import { Footer, Hero, Header, Services } from "components/organisms";
import Img from "assets/advance-parking-animation_poster.png"
import Video from "assets/advance-parking-animation.mp4"

function App() {
  return (
    <div className="bg-gray-10">
      <TopBar />
      <Header />
      <Hero />
      <Services />
      <div className="grid lg:grid-cols-2 items-center md:py-20 py-14 w-full px-4 md:px-10 xl:px-22 space-y-3 xl:space-y-5">
        <div className="">
          <p className="text-primary-200 text-xs tracking-widest">- <span className="font-semibold">INTEROPERABLE</span> ECOSYSTEM</p>
          <h1 className="tracking-wide text-secondary-300 md:text-6xl text-3xl font-semibold pt-3">Our Platform Makes Any Equipment Interoperable.</h1>
          <p className="tracking-wide text-secondary-500 md:text-[15px] text-sm font-light pr-5 py-5">Traditional parking equipment operates in silos and at best might work together with other products from the same manufacturer. In today’s new-age mobility, you need an ecosystem of integrated hardware and software components regardless of which version or vendor they all come from. <strong className="font-bold">Our GMP Platform delivers you that coherent interoperable ecosystem.</strong> </p>
          <button className='uppercase md:tracking-widest tracking-wide font-bold flex items-center justify-center rounded-md bg-primary-100 md:px-5 px-4 py-2.5 md:text-base text-sm text-white hover:bg-primary-200 focus:outline-none'>KNOW MORE</button>
        </div>
        <video autoplay="" loop="" muted="" playsinline="" webkit-playsinline="">
          <source src="https://www.getmyparking.com/src/interoperable.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="text-center flex flex-col items-center md:py-20 py-14 max-w-6xl w-full mx-auto px-4 md:px-10 xl:px-0 space-y-3 xl:space-y-5">
        <h1 className="tracking-wide text-secondary-300 md:text-[40px] md:leading-[50px] text-3xl font-semibold">Transforming Parking Into Intelligent Mobility Hubs</h1>
        <p className="tracking-wide text-secondary-500 md:text-[15px] text-sm font-light">Our technology enables seamless use of parking for pick up and drop of shared mobility, storage and deliveries for e-commerce, EV charging, and on the fly automotive services.</p>
        <video className="lg:max-w-4xl md:max-w-2xl w-full mx-auto" autoplay="" loop="" muted="" playsinline="" webkit-playsinline="">
          <source src="https://www.getmyparking.com/src/advance-parking-animation.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="md:px-6 px-4 xl:px-16 md:pt-16 pt-20 w-full footer bg-gray-50 border mt-20">
        <Footer />
      </div>
    </div>
  );
}

export default App;
