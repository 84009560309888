import React from 'react';
import HeroImg from "assets/hero.jpg";

function Hero() {
    return (
        <div className='relative md:-mt-32 -mt-24'>
            <div className="absolute inset-0 overflow-hidden w-full"><img src={HeroImg} alt="Hero" className='w-full h-full' /></div>
            <div className="relative h-[77vh] flex flex-col items-center justify-end pb-32">
                <h1 className='text-white md:text-7xl text-4xl pt-40 font-semibold uppercase tracking-wider'>Parking Systems</h1>
                <div className="flex items-center md:space-x-6 space-x-3 pt-9">
                    <button className='uppercase md:tracking-widest tracking-wide flex items-center justify-center rounded-sm bg-primary-100 md:px-7 px-4 py-2.5 md:text-base text-sm text-white hover:bg-primary-200 focus:outline-none'>FIND PARKING</button>
                    <button className='uppercase md:tracking-widest tracking-wide flex items-center justify-center rounded-sm bg-primary-100 md:px-7 px-4 py-2.5 md:text-base text-sm text-white hover:bg-primary-200 focus:outline-none'>Request a proposal</button>
                </div>
            </div>
        </div>
    )
}

export default Hero